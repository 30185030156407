import React, { useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Button, Container, TableTitle, Top, Wrapper } from './MyBookingsElements';
import axios from 'axios';
import { useState } from 'react';
import { token, url } from '../../Utils/Common';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';


const columns = [
    {
        field: 'createdAt',
        headerName: 'Booking Date',
        width: 180,
        type: 'date',

        editable: false,
        sortable: true
    },



    {
        field: 'room/branchName',
        headerName: 'Room/ Branch Name',
        sortable: false,
        width: 200,
        valueGetter: (params) =>
            `${params.getValue(params.id, 'room_name') || ''} / ${params.getValue(params.id, 'branch_name') || ''
            }`,
    },

    {
        field: 'start_date',
        headerName: 'Start Date',
        width: 150,
        type: 'date',
        editable: false,
        sortable: true
    },
    {
        field: 'end_date',
        headerName: 'End Date',
        // type: 'date',
        type: 'date',
        width: 150,
        editable: false,
    },
    {
        field: 'amount',
        headerName: 'Amount',
        type: 'number',
        width: 130,
        editable: false,
    },
    {
        field: 'status',
        headerName: 'Status',
        type: 'number',
        width: 180,

        cellClassName: (params) =>
            clsx('super-app', {
                active: params.value === "ACTIVE",
                expired: params.value === "EXPIRED",
            }),
    },


];

const useStyles = makeStyles({
    root: {
        '& .super-app.active': {
            color: 'green',
        },
        '& .super-app.expired': {

            color: 'red',

        },
    },
});


const MyBookings = () => {


    const [tenancyData, setTenancyData] = useState([]);

    useEffect(() => {

        //fetch all my bookings
        const headers = {
            'Authorization': `Bearer ${token}`,

        };

        axios.get(url + '/booking/mybookings', { headers })
            .then(response => {
                setTenancyData(response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });

        return () => {
        }
    }, [])






    //status styles
    const classes = useStyles();

    return (
        <Container>

            <Wrapper>
                <Top>
                    <TableTitle>
                        My Bookings
                    </TableTitle>
                    <Button to='/booking'>Book Hostel</Button>
                </Top>


                <div style={{ width: 'auto' }} className={classes.root}>
                    <DataGrid
                        rows={tenancyData}
                        columns={columns}
                        pageSize={10}
                        autoHeight={true}
                        checkboxSelection={false}
                        disableSelectionOnClick
                    />
                </div>
            </Wrapper>

        </Container>

    )
}

export default MyBookings
