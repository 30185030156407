import React from 'react';
import { FeatureContainer, FeatureButton, FeatureHeading } from './FeatureElements';

const Feature = () => {
  return (
    <FeatureContainer>
      <FeatureHeading>Be a part of us !</FeatureHeading>
      <p>A future is designed when like minds come together and make things happen.</p>
      <a href="#contact"><FeatureButton>Book A Room</FeatureButton></a>
    </FeatureContainer>
  );
};

export default Feature;
