import AtAglance from "../Components/AtAglance"
import Contact from "../Components/Contact"
import Feature from "../Components/Feature"
import Footer from "../Components/Footer"
import Hero from "../Components/Hero"
import Products from "../Components/Products"
import { productData } from "../Components/Products/data"
import Services from "../Components/Services"


const LandingPage = () => {
    return (
        <>
            {/* <NewNav/> */}
            <Hero />
            <AtAglance />
            <Products data={productData} />
            <Feature />
            <Services />
            {/* <OurCore imgStart={true} /> */}
            <Contact />
            <Footer />
        </>
    )
}

export default LandingPage
