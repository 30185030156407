import { useState } from 'react';
import axios from 'axios';
import { Container, FormWrapper, Form, FormTitle, FormLabel, FormInput, FormButton, FormDetails } from './FormElements'
import { token, today, url } from '../../Utils/Common';
import { useHistory } from 'react-router';
import { SpinnerCircular } from 'spinners-react';




const Profile = () => {


    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const [phone, setPhone] = useState('');
    const [nationalId, setNationalId] = useState('');
    const [schoolName, setSchoolName] = useState('');
    const [schoolReg, setSchoolReg] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [dob, setDob] = useState('')
    const [isMatched, setIsMatched] = useState(true);



    const handleUpdate = async (e) => {

        e.preventDefault();
        setIsLoading(true);

        if (confirmPassword !== newPassword) {
            setIsMatched(false);
            setIsLoading(false);

            return console.log("password don't match")


        }

        else {
            setIsMatched(true);

            const body =
            {
                mobile_number: phone,

                // "sex": "femaleww",
                dob: dob,
                national_id_number: nationalId,
                school_name: schoolName,
                school_reg_number: schoolReg,
                pwd: newPassword
                // year_of_study: "2nd year"

            };

            console.log(body);

            const headers = {
                'Authorization': `Basic ${token}`,
                "Content-Type": "application/json"

            };
            axios.put(url + '/tenant/update-profile', body, { headers })

                .then(response => {
                    setIsLoading(false)
                    console.log('update was successfull...')
                    history.push('/mybookings')


                })
                .catch(error => {
                    console.log("Something went wrong " + error)
                })
        }
    }


    return (

        <Container>

            <FormWrapper>

                <Form onSubmit={(e) => handleUpdate(e)}>
                    <FormTitle> Update Profile </FormTitle>
                    <FormDetails>
                        <FormLabel>Mobile Number</FormLabel>
                        <FormInput type="text" placeholder="Mobile Number"
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                    </FormDetails>
                    <FormDetails>
                        <FormLabel>National ID</FormLabel>
                        <FormInput type="text" placeholder="National ID"
                            onChange={(e) => setNationalId(e.target.value)}
                            required />
                    </FormDetails>
                    <FormDetails>
                        <FormLabel>DOB</FormLabel>
                        <FormInput type="date" placeholder="Date of birth"
                            max={today()}
                            onKeyDown={(e) => e.preventDefault()}
                            onChange={(e) => setDob(e.target.value)}
                            required />
                    </FormDetails>
                    <FormDetails>
                        <FormLabel>School Name</FormLabel>
                        <FormInput type="text" placeholder="School Name"
                            onChange={(e) => setSchoolName(e.target.value)}
                            required />
                    </FormDetails>
                    <FormDetails>
                        <FormLabel>School Reg Number</FormLabel>
                        <FormInput type="text" placeholder="School Reg Number"
                            onChange={(e) => setSchoolReg(e.target.value)}
                            required />
                    </FormDetails>
                    {/* <FormDetails>
                        <FormLabel>Passport Photo</FormLabel>
                        <FormInput type="file" placeholder="School Reg Number"
                        />
                    </FormDetails> */}
                    <FormDetails>
                        <FormLabel>New Password</FormLabel>
                        <FormInput type="password" placeholder="New Password"
                            onChange={(e) => setNewPassword(e.target.value)}
                            required />
                    </FormDetails>
                    <FormDetails>
                        <FormLabel>Confirm New Password</FormLabel>
                        <FormInput type="password" placeholder="Comfirm New Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required />
                    </FormDetails>
                    {!isMatched && <p style={{ color: "red" }}>Passwords don't match.</p>}

                    <FormDetails>
                        <FormButton type="submit">Update Profile</FormButton>
                    </FormDetails>
                    <FormDetails>
                        {
                            isLoading && <SpinnerCircular />
                        }
                    </FormDetails>


                </Form>
            </FormWrapper>
        </Container>
    )
}

export default Profile
