import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
    padding: 20px 0;

`

export const FormWrapper = styled.div`
    margin: 0% 15%;
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 0 15%;
    
    
    @media screen and (max-width: 960px){
        margin: 0% 2.5%;
        padding: 0;
    }
    
`
export const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
     padding-bottom: 30px;


`

export const Button = styled(Link)`
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ff7518;
    cursor: pointer;
    font-size: 18px;
    text-decoration: none;
    color: #fff;

    &:hover{
        transition: .3s ease-in-out;
        background-color: #fa9a5a;
        color: white;
    }

    @media screen and (max-width: 960px){
        font-size: 16px;
        
        padding: 5px auto;
    }
`



export const Form = styled.form`
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 8px rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 40px 20px;

    @media screen and (max-width: 960px){
        padding: 40px 20px;
    }
    
`

export const FormTitle = styled.h3`
 
   
    color: #006050;
    font-size: 30px;

`

export const FormDetails = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    
    
`


export const FormLabel = styled.label`
    font-size: 18px;
    color: black;
`

export const FormInput = styled.input`
    font-size: 16px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #dbe0e6;
`

export const FormSelect = styled.select`
    font-size: 16px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #dbe0e6;
    background-color: #fff;
`
export const FormOption = styled.option`
    
`

export const FormButton = styled.button`
    background-color: #ff7518;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 10px 0;
    cursor: pointer;
    font-size: 20px;

    &:hover {
    color: #fff;
    background: #fa9a5a;
    
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

`

export const UserTitle = styled.p`

    &:hover{


    }
`

export const ForgotPdw = styled(Link)`

    text-decoration: none;
    color: blue;
`