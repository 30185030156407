import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { token, url } from '../../Utils/Common'
import { Top } from '../Form/FormElements'
import { Button } from '../MyBookings/MyBookingsElements'
import { Container, Data, DataSection, Details, Form, Labels, Title, Wrapper } from './ProfileElements'

const MyProfile = () => {
    const [data, setData] = useState('');

    const [phone, setPhone] = useState('');
    const [trackChange, setTrackChange] = useState(0);


    //handle update


    const updateProfile = () => {

        //update tenant data
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        const body = {
            mobile_number: phone
        }

        axios.put(url + '/tenant/update-profile', body, { headers })
            .then(response => {

                console.log(response.data)

                alert("Phone number was updated successfully to " + phone)
                setTrackChange(trackChange + 1);

            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }





    useEffect(() => {
        //fetch tenant data
        const headers = {
            'Authorization': `Bearer ${token}`,

        };

        axios.get(url + '/tenant/mydata', { headers })
            .then(response => {
                console.log(response.data)
                setPhone(response.data.mobile_number)
                setData(response.data)

            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [trackChange])



    return (
        <Container>
            <Wrapper>

                <Top>
                    <Title>Personal Information</Title>
                    <Button onClick={updateProfile}>Update</Button>
                </Top>
                <hr />

                <DataSection>

                    <Form>
                        <Details>
                            <Labels>NATIONAL ID:</Labels>
                            <Data value={data.national_id_number} disabled />
                            {/* <Data>: {data.national_id_number}</Data> */}
                        </Details>


                        <Details>
                            <Labels>NAME </Labels>
                            <Data value={data.name}
                                disabled />
                            {/* <Data>: {data.name}</Data> */}
                        </Details>
                        <Details>
                            <Labels>SEX </Labels>
                            <Data value={data.sex}
                                disabled />
                            {/* <Data>: {data.sex}</Data> */}
                        </Details>

                        <Details>
                            <Labels>DATE OF BIRTH </Labels>
                            <Data value={data.dob}
                                disabled />
                            {/* <Data>: {data.dob}</Data> */}
                        </Details>

                        <Details>
                            <Labels>EMAIL </Labels>
                            <Data value={data.email}
                                disabled />


                        </Details>

                        <Details>
                            <Labels>PHONE </Labels>
                            <Data value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                            />

                        </Details>

                        <Details>
                            <Labels>SCHOOL REG NO. </Labels>
                            <Data value={data.school_reg_number}
                                disabled />

                        </Details>
                        <Details>
                            <Labels>SCHOOL </Labels>
                            <Data value={data.school_name}
                                disabled />

                        </Details>
                    </Form>
                </DataSection>

            </Wrapper>
        </Container>
    )
}

export default MyProfile
