import styled from "styled-components";
import { Link } from "react-router-dom";


export const FooterContainer = styled.div`
    /* background-color: rgba(151,22,115,0.81); */
    background-color: #006050;
    color: #fff;
    padding: 1.5% 0%;
    

`

export const FooterWrapper = styled.div`
    margin: 0% 15%;


    display: grid;
    grid-template-columns: repeat(3 1fr);
    grid-template-areas: 
    "contacts links socials";

    @media screen and (max-width: 960px){

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        
        /* flex-wrap: wrap; */

        /* margin: 0% 2.5%;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        
        "links socials"  

        "contacts contacts"
         */


    }


`

export const FooterContacts = styled.div`
    grid-area: contacts;

    a{
        text-decoration: none;
    }
  
`

export const FooterLinks = styled.div`
    grid-area: links;

    @media screen and (max-width:960px){
        display: none;
    }
    
`

export const FooterSocials = styled.div`
    grid-area: socials;
  

    @media screen and (max-width: 960px){
        margin-left: 30px;
    }
    
`

export const FooterTitles = styled.div`
    text-align: left;
    font-family: 'Cardo';
    font-size: 26px;
    margin-bottom: 2.5%;
    font-weight: bold;
    color: #ff7518;

    @media screen and (max-width: 960px){
        font-size: 24px;
    }
`

export const FooterItems = styled.a`
    /* border: 2px red dotted; */
    
    display: flex;
    justify-content: left;
    align-items: left;
    text-decoration: none;
    color: #fff;
    line-height: 1.5;
    font-size: 24px;
    

    @media screen and (max-width: 960px){
        font-size: 18px;
    }

    &:hover{
        /* transform: scale(1.2); */
        color: #ff7518;
    }   

`

export const FooterP = styled.p`
    text-align: center;
    line-height: 1.5;
    font-size: 24px;

    @media screen and (max-width: 960px){
        font-size: 18px;
        
    }

    a{
        color: #fff;
        text-decoration: none;
    }
    a:hover{ 
        transform: scale(1.2);
    }
`

export const FooterIcons = styled.a`
    display: flex;
    justify-content: left;
    align-items: center;
    text-decoration: none;
    color: #fff;
    text-align: center;
    font-size:25px;
    line-height: 2;
    padding-bottom: 20px;
    /* width: 60px; */

    height: 1.6em;
    

   
    &:hover{
        /* transform: scale(1.5); */
        color: #ff7518;
        /* transform: rotateX('deg(50)') */
    }   

    @media screen and (max-width: 960px){
        font-size: 18px;
    }
    
    `



