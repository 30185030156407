import { useState } from 'react'
import { Container, FormWrapper, Form, FormTitle, FormInput, FormButton, FormDetails } from './FormElements'
import axios from 'axios'
import { logout, token, url } from '../../Utils/Common';


const ChangePassword = () => {

    const [isLoading, setisLoading] = useState(false);
    const [isMatched, setIsMatched] = useState(true);
    const [isCurrentPwdCorrect, setIsCurrentPwdCorrect] = useState(true)
    const [currentPwd, setCurrentPwd] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    const changePwd = (e) => {

        e.preventDefault()
        //check if new password and confirm new password match


        if (newPassword !== null && confirmPassword !== newPassword) {
            setIsMatched(false);

        }
        else {
            setIsMatched(true)
            setisLoading(true)
            //now check if the current password is correct
            //fetch all my bookings
            const headers = {
                'Authorization': `Bearer ${token}`,

            };
            const body = {
                currentPassword: currentPwd,
                newPassword: newPassword
            }

            axios.put(url + '/tenant/changePassword', body, { headers })
                .then(response => {
                    // setTenancyData(response.data);
                    // console.log(response.data)
                    setIsCurrentPwdCorrect(true);
                    setisLoading(false)
                    alert("Password changed successfully!")
                    logout()
                })
                .catch(error => {
                    setIsCurrentPwdCorrect(false);
                    setisLoading(false)
                    console.error('There was an error!', error);
                });

        }




    }




    return (

        <Container>
            <FormWrapper>
                <Form onSubmit={(e) => changePwd(e)}>
                    <FormTitle> Change Password </FormTitle>
                    <FormDetails>

                        <FormInput type="password" placeholder="Enter Old Password"
                            onChange={(e) => setCurrentPwd(e.target.value)}
                            required
                        />
                    </FormDetails>
                    <FormDetails>
                        <FormInput type="password" placeholder="Enter New Password"
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </FormDetails>
                    <FormDetails>
                        <FormInput type="password" placeholder="Confirm New Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </FormDetails>
                    {!isCurrentPwdCorrect && <p style={{ color: "red" }}>Current Password is Wrong... Please try again!</p>}
                    {!isMatched && <p style={{ color: "red" }}>Passwords don't match.</p>}


                    <FormDetails>
                        {!isLoading ? <FormButton>Change Password</FormButton> :
                            <FormButton>Changing Password</FormButton>}

                        {/* {!loginLoad && <FormButton onClick={(e) => login(e)}>Change Password</FormButton>}
                        {loginLoad && <FormButton onClick={(e) => login(e)}>Please wait...</FormButton>} */}

                    </FormDetails>

                </Form>
            </FormWrapper>
        </Container>
    )
}

export default ChangePassword
