// return the data in the localstorage

export const token = localStorage.getItem('token');
export const nameLocal = localStorage.getItem('name');
export const userId = localStorage.getItem('userId');
export const emailLocal = localStorage.getItem('email');
export const expireAt = localStorage.getItem('expireAt');


export const url = "https://api.mintresidence.com"
// export const url = "http://localhost:3037"


export const logout = () => {
  localStorage.clear();
  window.location.href = "/login"

}


export const getToken = () => {
  return localStorage.getItem('token') || null;
}


//today
export const today = () => {
  // Use Javascript
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }

  today = yyyy + '-' + mm + '-' + dd;

  return today
}


//chech token expire function 
export const checkExpired = () => {
  // //check if token has expired
  const expireDateTime = new Date(expireAt)
  var dt = new Date();
  if (expireAt !== null) {
    if (dt > expireDateTime) {
      logout()
    }
  }

}


