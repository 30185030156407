import { useState, useEffect } from 'react'
import { ContactContainer, ContactLeft, ContactRight, ContactSubTitle, ContactTitle, ContactWrapper, Form, FormButton, FormInput, FormTextArea } from './ContactsElements'
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { useMediaQuery } from 'react-responsive';
import { FormLabel, FormOption, FormSelect } from '../Form/FormElements';
import { today, url } from '../../Utils/Common';
import axios from 'axios';
import { stringToArray } from '../../Utils/Bookings/BookingUtils';


const Contact = () => {


    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [branch, setBranch] = useState([]);
    const [branchId, setBranchId] = useState('');
    const [roomTypes, setRoomTypes] = useState('');
    const [roomTypeName, setRoomTypeName] = useState('');


    const [checkin, setCheckin] = useState('');
    const [checkout, setCheckout] = useState('');
    const [message, setMessage] = useState('');
    const [selectedBranch, setSelectBranch] = useState('');
    const [period, setPeriod] = useState("");

    // console.log(period);
    // console.log(new Date(checkin))
    //calculate checkout date

    // setCheckout(new Date(checkin))

    //converting roomTypes string (originated from db) to array so that we can use map 
    const roomTypeArray = stringToArray(roomTypes) // ['Single', '2 bedrooms']

    const handleCheckOut = (e) => {
        console.log(typeof e)
        let checkOutDate = new Date(checkin);
        checkOutDate.setDate(checkOutDate.getDate() + parseInt(e));

        setCheckout(checkOutDate);

        console.log("hehe " + e)


    }

    console.log("HEhe" + checkout)

    const handleRoomType = (e) => {
        setRoomTypeName(e);

    }

    const handleBranch = (e) => {
        branch.filter(branch => branch.id === e).map(filtered => {
            return (setBranchId(filtered.id),
                setRoomTypes(filtered.room_type),
                setSelectBranch(filtered.name));
        });
    }


    //fetch branches
    useEffect(() => {

        axios.get(url + '/branch/')
            .then(result => {
                setBranch(result.data);
                console.log(result.data)
            })
            .catch(error => {
                console.log(error)
            })


    }, [])


    //handle Leads(contact form)
    const handleLeads = (e) => {
        e.preventDefault();
        const body = {
            name: name,
            email: email,
            phone: phone,
            branch: branch,
            roomType: roomTypes,
            checkin: checkin,
            checkout: checkout,
            message: message,
        }

        window.location.href = `mailto:hello@mintresidence.com?subject=Hostel Inquiry
        &body=Hi! I am ${name}. I would like to know more about your hostel in ${branch}, room type ${roomTypes}. %0D%0A
        I intend to check in on ${checkin} for a duration of ${period}. %0D%0A%0D%0A

        
        %0D%0A%0D%0A

        ${message}%0D%0A%0D%0A

        Regards, %0D%0A
        ${name}, %0D%0A
        ${phone}`

    }



    //map stuffs
    const [selected, setSelected] = useState({});
    const onSelect = item => {
        setSelected(item);
    }

    var mapStyles = {}
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });
    if (isTabletOrMobile) {

        mapStyles = {

            height: "70vh",
            width: "100%",
            borderRadius: "0px 0px 10px 10px"
        };
    } else {
        mapStyles = {

            height: "100%",
            width: "100%",
            borderRadius: "20px 0px 0px 20px"
        };
    }
    const defaultCenter = {
        lat: -1.291984, lng: 36.846943
    };

    const locations = [

        {
            name: "Shauri Moyo Branch",
            location: {
                lat: -1.291984,
                lng: 36.846943
            },
        }
    ];

    return (
        <ContactContainer id='contact'>
            <ContactWrapper>
                <ContactLeft>

                    <LoadScript
                        googleMapsApiKey='AIzaSyDy_vLRXu4U7rHJ7fyowXSLv8pyTElA75o'>
                        <GoogleMap
                            mapContainerStyle={mapStyles}
                            zoom={13}
                            center={defaultCenter}>
                            {
                                locations.map(item => {
                                    return (
                                        <Marker key={item.name} position={item.location} />
                                    )
                                })
                            }

                            {
                                locations.map(item => {
                                    return (
                                        <Marker key={item.name}
                                            position={item.location}
                                            onClick={() => onSelect(item)}
                                        />
                                    )
                                })
                            }
                            {
                                selected.location &&
                                (
                                    <InfoWindow
                                        position={selected.location}
                                        clickable={true}
                                        onCloseClick={() => setSelected({})}
                                    >
                                        <p>{selected.name}</p>
                                    </InfoWindow>
                                )
                            }
                        </GoogleMap>
                    </LoadScript>

                </ContactLeft>

                <ContactRight>
                    <ContactTitle>Contact US</ContactTitle>
                    <ContactSubTitle>Fill out the form below to book your room now
                    </ContactSubTitle>

                    <Form onSubmit={handleLeads}>
                        {/* <FormLabel>Name</FormLabel> */}
                        <FormLabel>Full Name</FormLabel>
                        <FormInput type="text" placeholder="Name" value={name}
                            onChange={(e) => setName(e.target.value)} required />
                        {/* <FormLabel>Phone Number</FormLabel> */}
                        <FormLabel>Phone Number</FormLabel>
                        <FormInput type="text" placeholder="0701234567"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)} required />
                        {/* <small> Format: 254701234567</small> */}
                        <FormLabel>Email</FormLabel>
                        <FormInput type="email" placeholder="Email" value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required />


                        <FormLabel>Branch Name</FormLabel>
                        <FormSelect id="branch" name="branchName" onChange={(e) => setBranch(e.target.value)} required >
                            <FormOption>Select Branch</FormOption>
                            <FormOption value={branch.id} key={branch.id} name="branchName">Shauri Moyo</FormOption>

                        </FormSelect>

                        <FormLabel>Room Type</FormLabel>
                        <FormSelect id="room" name="roomType" onChange={(e) => setRoomTypes(e.target.value)} required>
                            <FormOption name="roomType">Select Room Type</FormOption>
                            <FormOption value="Single Bed" name="roomType">Single Bed</FormOption>
                            <FormOption value="Twin Room" name="roomType">Twin Room</FormOption>
                            <FormOption value="Four Person Room" name="roomType">Four Person Room</FormOption>

                        </FormSelect>

                        <FormLabel>Tentative_check_in</FormLabel>
                        <FormInput min={today()} type="date" placeholder="date" value={checkin}
                            onChange={(e) => setCheckin(e.target.value)} required />

                        <FormLabel>Duration</FormLabel>
                        <FormSelect id="room" name="roomType"
                            onChange={(e) => setPeriod(e.target.value)}
                            disabled={!checkin} required>
                            <FormOption value="0">Select Period/Duration</FormOption>
                            <FormOption value="1 Month">1 Month</FormOption>
                            <FormOption value="1 Semester/3 Months">1 Semester/3 Months</FormOption>
                            <FormOption value="1 Year">1 Year</FormOption>
                        </FormSelect>

                        {/* <FormLabel>Tentative check_out</FormLabel>
                        <FormInput type="date" placeholder="date" value={checkout}
                            min={checkin}
                            onChange={(e) => setCheckout(e.target.value)} required /> */}

                        <FormTextArea placeholder="Message"
                            required
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}></FormTextArea>
                        <FormButton>Send</FormButton>
                    </Form>

                </ContactRight>
            </ContactWrapper>
        </ContactContainer>
    )
}

export default Contact
