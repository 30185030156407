import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin:0;
    padding: 0;
    font-family: 'Cardo', sans-serif;
    
  }
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  

  @media screen and (max-width: 991px){
    padding-right: 30px;
    padding-left: 30px;
  }

  `

export const Button = styled.button`
  border-radius: 4px;
  background: #4B59F7;
  white-space: nowrap;
  padding: 12px 64px;
  color: #fff;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  
  &:hover{
    transition: all 0.3s ease-out;
    background: #fff;
    background: #0467FB;
  }

  @media screen and (max-width: 960px){
    width: 100%;
  }
  `

export const ContentDesc = styled.div`
font-size: 21px;
 line-height:2;
  color:#212121;
max-width: 100%;
text-align: center;
margin-left: auto;
margin-right: auto;




@media screen and (max-width: 960px){
  font-size: 16px;
  text-align: center;
  margin-left: 2.5%;
  margin-right: 2.5%
  
}

`

export default GlobalStyle;