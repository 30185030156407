import styled from 'styled-components';

export const HeroContainer = styled.div`
  background-color: #dbe0e6;
  min-height: calc(70vh - 80px);
  padding: 2.5% 15%;


  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "content image";

  @media screen and (max-width: 960px){
    grid-template-columns: 1fr;
    grid-template-areas:
      "image"
      "content" ;
    padding: 2.5% 2.5%;

  }

`

export const RightSide = styled.div`
 
  grid-area: image;
  padding: 0 2.5%;

  display: flex;
  justify-content: center;
  align-items: center;

  

  @media screen and (max-width: 960px){
    margin-bottom: 20px;
  }


 

`
export const ImageSection = styled.div`
/* border: 2px dotted red; */


  img{
    width: 100%;
    height: auto;
  }

  
  
`


export const LeftSide = styled.div`
  grid-area: content;
  margin-top: auto;
  margin-bottom: auto;

  @media screen and (max-width: 960px){
    padding: 0 2.5%;
    text-align: center;
  }


`

export const Content = styled.div`
  /* text-align: center; */
  padding-right: 5%;
`

export const Heading = styled.h3`
  font-size: 45px;
  font-family: "Kaushan Script", cursive;

  @media screen and (max-width: 960px){
    font-size: 36px;
  }
`

export const Paragraph = styled.p`
  font-size: 22px;
  margin-bottom: 20px;
  line-height:2;
  color:#212121;

  @media screen and (max-width: 960px){
    font-size: 18px;
  }
  
`

export const Button = styled.button`
  padding:5px 80px ;
  border: none;
  background-color: #FF8200;
  border-radius: 20px;
  font-size: 24px;
  color: #fff; 
  cursor: pointer;

  a{
    text-decoration: none;
    color: #fff;
  }

  &:hover {
    background: #fa9a5a;;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #fff;

  }




  @media screen and (max-width: 960px){
    padding:10px 70px ;
    font-size: 20px;
  }


  

`