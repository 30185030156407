import styled from "styled-components";

export const Container = styled.div`

    

`

export const Wrapper = styled.div`
    margin: 2.5% 15%;
    padding: 2.5%;
    box-shadow: 0 0 8px rgba(0,0,0,0.1);

    @media screen and (max-width: 960px)
    {
        margin: 5% 2.5%;
    }

`

export const Top = styled.div`

`

export const Title = styled.h3`
    padding-left: 1.5%;    

`
export const DataSection = styled.div`
padding: 2.5% 0% ;


`



export const Details = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 5%;

    @media screen and (max-width: 960px)
    {
        grid-template-columns: 1fr;
    }

`

export const Labels = styled.div`

    font-weight: bold;
    font-size: 20px;

    @media screen and (max-width: 960px)
    {
       font-size: 18px;
    }
`

export const Data = styled.input`
font-size: 20px;
border: none;

@media screen and (max-width: 960px)
    {
       font-size: 18px;
    }

`


export const Form = styled.form`


`