import styled from "styled-components";
import { Link, NavLink } from 'react-router-dom';

export const Nav = styled.nav`
    background: #fff;
    height: 80px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;

    @media screen and (max-width: 960px){
        height: 70px;
    }
    `;

export const NavbarContainer = styled.nav`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    margin-left: 15%;
    margin-right: 15%;
  

  @media screen and (max-width: 991px){
    /* margin-right: 30px; */
    margin-left: 30px;
    height: 70px;
  }
    `

export const NavLogo = styled(Link)`
    color: #006050;
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;  
    
    @media screen and (max-width: 960px)
    {
        font-size: 1.5rem;
        
    }
    `

export const NavImg = styled.img`
    height: 80px;

    @media screen and (max-width: 960px){
        height: 70px;
    }

    `

export const MobileIcon = styled.div`
    display: none;
    
    @media screen and (max-width: 960px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;

    }`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    
    
    @media screen and (max-width: 960px){
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 70px;
        left: ${({ click }) => (click ? 0 : '-100%')};
        opacity: 1;
        transition: all 0.5s ease;
        
        background: #006050;

    }`;

export const NavItem = styled.li`
    height: 80px;
    border-bottom: 2px solid transparent;
    
    a{
        color: #000;
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 0.5rem 1rem;
        height: 100%;
    }
    &:hover{
        border-bottom: 5px solid #fff;  
    }
    
    @media screen and (max-width: 960px){
        width: 100%;
        

        &:hover{
            border: none;
        }
    }`

export const NavLinks = styled(NavLink)`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    
    @media screen and (max-width: 960px){
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        color: #fff;

        &:hover {
            color: #4b59f7;
            transition: all 0.3s ease;
        }
    }
    
    // dataBranch.map(branch => {
    //     return console.log(branch.name);
    // })

`
export const NavLogout = styled.a`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;

    @media screen and (max-width: 960px){
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

        &:hover {
            color: #4b59f7;
            transition: all 0.3s ease;
        }
    }

`
