import React from 'react'
import { FaFacebookSquare, FaInstagram, FaTwitterSquare } from 'react-icons/fa';
import { FooterContacts, FooterContainer, FooterIcons, FooterItems, FooterLinks, FooterP, FooterSocials, FooterTitles, FooterWrapper } from './FooterElements'

const Footer = () => {


    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterContacts>
                    <FooterTitles>
                        Contacts
                    </FooterTitles>
                   
                
                    <a href="tel:0797908782">
                        <FooterItems title=''>
                            +254 797 908 782
                        </FooterItems>
                    </a>

                    <a href="mailto:hello@mintresidence.com?subject=Hostel Inquiry&body=Hi! I am interested in knowing more about The Mint Residence Hostel. Please call me back" rel="noreferrer" target="_blank" title="Send us mail">
                        <FooterItems>
                            hello@mintresidence.com
                        </FooterItems>
                    </a>

                </FooterContacts>
                <FooterLinks>

                    <FooterTitles>Links</FooterTitles>

                    <FooterItems href="/#rooms">
                        Rooms
                    </FooterItems>

                    {/* <a >Take a tour </a> */}
                    <FooterItems href="/#contact">

                        Contact us
                    </FooterItems>

                </FooterLinks>

                <FooterSocials>
                    <FooterTitles>
                        Socials
                    </FooterTitles>

                    <FooterIcons href="https://www.facebook.com/profile.php?id=100073205085767" rel="noreferrer" target="_blank">

                        <FaFacebookSquare />Facebook

                    </FooterIcons>

                    <FooterIcons href="https://www.instagram.com/mint_residence/" rel="noreferrer" target="_blank" >
                        <FaInstagram />Instagram
                    </FooterIcons>

                    <FooterIcons href="https://twitter.com/mint_residence" rel="noreferrer" target="_blank" >
                        <FaTwitterSquare />Twitter
                    </FooterIcons>

                </FooterSocials>
            </FooterWrapper>
            <FooterP style={{ margin: "20px " }}>Visit us to find your new home.<br />
                We are located on Ambira Road Off Jogoo Road, Nairobi (opposite YMCA National Training Institute)</FooterP>
            <FooterP style={{ paddingTop: "20px" }}>
                &copy; The Mint Residence. All rights reserved.
            </FooterP>



        </FooterContainer>
    )
}

export default Footer
