import styled from 'styled-components';

export const ProductsContainer = styled.div`
  /* width: 100vw; */
  /* height: calc(100vh - 80px); */
  /* padding: 5rem calc((100vw - 1300px)/2); */
  /* padding-top: 7rem; */
  /* padding-bottom: 5rem; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #dbe0e6;
  color: #000;
  min-height: calc(100vh - 70px) ;


  @media screen and (max-width: 960px){
    padding-top: 5%;
    padding-bottom: 5%;
  }
  `;

export const ProductWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
 
  height: 100%;
  justify-content: center;
  align-items: center; 
  /* margin: 0 auto; */

`;

export const Title = styled.h2`
    font-family: 'Kaushan Script';
    color: #006050;
    font-size: 36px;
    font-weight: normal;

    @media screen and (max-width: 960px){
        font-size: 26px;
    }
`



export const ProductCard = styled.div`
  position: relative;
  margin: 1rem 4rem;
  line-height: 2;
  width: 370px;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 8px 5px;

 /* @media screen and (max-width: 1440px){
    width: 30px;
 } */

  
  &:hover{
    transform: scale(1.02) ;
    transition: .4s ease-out;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

  }

  @media screen and (max-width: 960px){
    margin: 2.5%;

    &:hover{
    transform: none;
  }
    
  }



  

`;

export const ProductImg = styled.img`
  height: 300px;
  min-width: 300px;
  max-width: 100%;
  /* box-shadow: 8px 8px #fdc500; */
  border-radius: 10px;
  transition: all 1 linear;

  transition: transform .9s, filter 1.5s ease-in-out;
  transform-origin: center center;
  /* filter: brightness(90%); */

  &:hover{
    transform: scale(1.04);
  }

  @media screen and (max-width: 960px){
    &:hover{
    
    transform: scale(1);
  }

  }


  
`;

export const ProductsHeading = styled.h1`
  font-size: clamp(2rem, 2.5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
`;

export const ProductTitle = styled.h2`
  font-weight: 400;
  font-size: 1.2rem;
  font-family: 'Kaushan Script', cursive;
  color: #006050;
  margin-right: 15px;
  padding: 0 10px;
  border-radius: 15px;
  background: white;
`;

export const ProductInfo = styled.div`
  display: flex;
  position: relative;
  top: -40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
`;

export const ProductTop = styled.div`
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
  top: -55px;
  /* justify-content: center; */
`;


export const ProductDesc = styled.p`
  margin-bottom: 1rem;
`;

export const ProductPricing = styled.p`
  /* margin-bottom: 1rem; */
  font-size: 0.8rem;
  background-color: #A1CCA5;
  padding : 2px 10px;
  border-radius: 20px;


`;

export const ProductButton = styled.button`
  font-size: 1rem;
  padding: 0.5rem 3rem;
  border: none;
  border-radius: 20px;  
  background: #ff7518;
  color: #fff;
  transition: 0.2s ease-out;

  &:hover {
    background: #fa9a5a;;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #fff;

  }

  a{
    color: #fff;
    text-decoration: none;
  }

  a:hover{
    color: #fff;
  }
`;
