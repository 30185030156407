import { useState } from 'react'
import { Container, FormWrapper, Form, FormTitle, FormLabel, FormInput, FormButton, FormDetails, ForgotPdw } from './FormElements'
import axios from 'axios'
import { url } from '../../Utils/Common';


const Login = () => {



    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [wrong, setWrong] = useState(false);
    const [loginLoad, setLogin] = useState(false);


    const login = async (e) => {

        e.preventDefault(); // prevent the form from reloading when submit button is pressed

        setLogin(true);
        // POST request using axios with error handling
        const body = { email: email, password: password };

        axios.post(url + '/tenant/login', body)

            .then(response => {

                console.log('Login was Successfull');
                setWrong(false);

                var dt = new Date();
                dt.setHours(dt.getHours() + 1)

                localStorage.setItem('token', response.data.token);
                localStorage.setItem('name', response.data.name);
                localStorage.setItem('userId', response.data.userId);
                localStorage.setItem('email', response.data.email);
                localStorage.setItem('firstLogin', response.data.firstLogin);
                localStorage.setItem('expireAt', dt);

                console.log(dt)

                setLogin(false);






                response.data.firstLogin === 1 ? window.location.href = "/updateprofile" :
                    window.location.href = "/mybookings"


            })

            .catch(error => {
                // this.setState({ errorMessage: error.message });
                console.log('There was an error!', error);
                setWrong(true);
                setLogin(false);

            });
    }



    return (

        <Container>
            <FormWrapper>
                <Form >
                    <FormTitle> Login </FormTitle>
                    <FormDetails>
                        <FormLabel>Email</FormLabel>
                        <FormInput type="email" placeholder="Email Address"
                            onChange={(e) => setEmail(e.target.value)} />
                    </FormDetails>
                    <FormDetails>
                        <FormLabel>Password</FormLabel>
                        <FormInput type="password" placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)} />
                    </FormDetails>
                    {wrong && <p style={{ color: "red", paddingBottom: "10px" }}>You entered wrong credentials. Please try again...</p>}

                    <FormDetails>

                        {loginLoad && <FormButton onClick={(e) => login(e)}>Logging in</FormButton>}
                        {!loginLoad && <FormButton onClick={(e) => login(e)}>Log in</FormButton>}

                    </FormDetails>
                    <ForgotPdw to="/reset">
                        {<p style={{ color: "light_blue" }}>Forgot Password?</p>}
                    </ForgotPdw>


                </Form>
            </FormWrapper>
        </Container>
    )
}

export default Login
