import { Button, Content, Heading, HeroContainer, ImageSection, LeftSide, Paragraph, RightSide } from "./HeroElements";
import girlStudy from '../../images/herogirl.png';

const Hero = () => {

  return (
    <>
      <HeroContainer>



        <LeftSide>
          <Content>
            {/* #FF8200 */}
            <Heading style={{ color: "#006050" }} >
              Karibu!
            </Heading>
            <Heading>
              We have a home for you!


            </Heading>

            <Paragraph>
              Your safe, clean and relaxed hostel that accomodates you during your academics within Nairobi. You can stay with us for as long as you want even if it's just for a week
            </Paragraph>
            {/* <Paragraph>
              If you need a quiet study space or a friendly community that is safe and secure, our serviced living accommodation creates an environment that will help you focus on working towards your success! We promise to take care of the rest.
            </Paragraph> */}

            <a href="#rooms">
              <Button>
                Learn More
              </Button>
            </a>

          </Content>

        </LeftSide>

        <RightSide>
          <ImageSection>
            <img src={girlStudy} alt="The mint residence hostel girl" />
          </ImageSection>

        </RightSide>



      </HeroContainer>
    </>
  );
};

export default Hero;
