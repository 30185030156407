import { useState } from 'react'
import { Container, FormWrapper, Form, FormTitle, FormLabel, FormInput, FormButton, FormDetails, ForgotPdw } from './FormElements'
import axios from 'axios'
import { useHistory } from 'react-router'
import { url } from '../../Utils/Common'




const ForgotPassword = () => {

    const [email, setEmail] = useState('');

    const history = useHistory();

    const handleReset = (e) => {

        e.preventDefault()
        const body = {
            email: email
        }

        axios.put(url + '/tenant/resetPwd', body)
            .then(response => {
                // console.log(response.status)
                alert('Check sms(on phone) for temporary password ')
                history.push('/login')
            })
            .catch(error => {
                // setIsCurrentPwdCorrect(false);
                // console.error('There was an error!', error);
                alert('User not found')

            });
    }


    return (

        <Container>
            <FormWrapper>
                <Form onSubmit={(e) => handleReset(e)}>
                    <FormTitle> Recover Password </FormTitle>
                    <FormLabel style={{ marginBottom: "20px" }}>A reset link will be sent to the registered phone number.</FormLabel>
                    <FormDetails>
                        <FormLabel>Email</FormLabel>
                        <FormInput type="email" placeholder="Enter your Email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </FormDetails>

                    <FormDetails>
                        <FormButton>Reset Password</FormButton>
                    </FormDetails>

                    <ForgotPdw to="/login">
                        {<p style={{ color: "light_blue" }}>Return to Login</p>}
                    </ForgotPdw>



                </Form>
            </FormWrapper>
        </Container>
    )
}

export default ForgotPassword
