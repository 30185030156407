
import {
  ProductsContainer,
  ProductWrapper,
  ProductTitle,
  ProductCard,
  ProductImg,
  ProductInfo,
  ProductTop,
  ProductDesc,
  ProductPricing,
  ProductButton,
  Title
} from './ProductsElements';

const Products = ({ data }) => {
  return (
    <ProductsContainer id='rooms'>
      <Title>Select A Room</Title>
      <ProductWrapper>
        {data.map((product, index) => {
          return (
            <ProductCard key={index}>
              <ProductImg src={product.img} alt={product.alt} />
              <ProductTop>
                <ProductTitle>{product.name}</ProductTitle>
                <ProductPricing title='per month'>{product.pricing}</ProductPricing>
              </ProductTop>

              <ProductInfo>
                <ProductDesc>{product.desc}</ProductDesc>
                <a href="/explore"><ProductButton>Explore</ProductButton></a>

              </ProductInfo>
            </ProductCard>
          );
        })}
      </ProductWrapper>
    </ProductsContainer>
  );
};

export default Products;
