import React from 'react'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


import { ButtonSection, CarouselDiv, CarouselSection, Container, ContentSide, FeatureSection, GalleryButton, Image, ImgSide, ListSection, Title, Wrapper } from './GalleryElements';
import Footer from '../Footer';
import { images } from './data';
import { InnerTitle, Service, ServiceDesc, ServiceIcon, ServicesSection } from '../Services/ServicesElements';
import Services from '../Services';
import { services } from '../Services/data';

const Gallery = () => {




    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2.8,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    return (
        <>
            <Container>
                <Wrapper>

                    <FeatureSection>
                        <ContentSide style={{ color: "#000" }}>
                            <Title style={{ textAlign: "center" }}>
                                Covid 19 Measures
                            </Title>
                            <ListSection>
                                <ul>

                                    <li>Professional and more frequent <b>deep cleaning</b> of communal areas <b>with hospital grade sanitizer</b></li>
                                    <li> <b> Antibacterial wipes</b> available for residents and staff to use</li>
                                    <li> <b>Increased signage</b> across the building reminding everyone to wash their hands</li>


                                </ul>
                            </ListSection>



                            <ServicesSection style={{ marginBottom: "15px" }}>

                                {services.map(srvc => {

                                    return (
                                        <Service style={{ width: "25%" }}>
                                            <ServiceIcon style={{ fontSize: "25px" }}>{srvc.icon}</ServiceIcon>

                                            <InnerTitle style={{ fontSize: "16px" }}>{srvc.title}</InnerTitle>
                                            {/* <ServiceDesc>{srvc.desc}</ServiceDesc> */}
                                        </Service>
                                    )

                                })}

                            </ServicesSection>

                            <ButtonSection>
                                <a href="/#contact"><GalleryButton>Book A Room</GalleryButton></a>

                            </ButtonSection>
                        </ContentSide>

                        <ImgSide>
                            <Image src={`${process.env.PUBLIC_URL}/mintPics/format27.jpg`} alt="The mint residence " />

                        </ImgSide>
                    </FeatureSection>

                    <CarouselSection>
                        <Title style={{ paddingBottom: "20px", textAlign: "center" }}>Inside The Mint Residence</Title>
                        <div style={{ margin: "0 1.25%", borderRadius: "20px" }}>
                            <Carousel
                                responsive={responsive}
                                showDots={false}
                                keyBoardControl={true}
                                draggable={true}
                                infinite={true}
                                // itemClass="carousel-item-padding-100-px"
                                containerClass="carousel-container">



                                {images.map(img => {

                                    return (
                                        <CarouselDiv >
                                            <img src={`${process.env.PUBLIC_URL}/mintPics/${img}`} alt="The Mint Residence" style={{ borderRadius: "10px" }} />
                                            {/* <p className="legend">Legend 1</p> */}
                                        </CarouselDiv>
                                    )
                                })}
                            </Carousel>
                        </div>

                    </CarouselSection>

                </Wrapper>
                <Footer />

            </Container>

        </>


    )
}

export default Gallery