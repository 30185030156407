
export const stringToArray = (stringtoconvert) => {
  let newString = stringtoconvert;
  newString = newString.replace('[', '')
  newString = newString.replace(']', '')
  return newString.split(',')
}

export const totalCostforRoom = (startdate, enddate, cost) => {
  let date1 = new Date(startdate);
  let date2 = new Date(enddate);
  let Difference_In_Time = date2.getTime() - date1.getTime();
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days * cost;

}



