import styled from "styled-components";

export const Container = styled.div`
    /* padding: 0 15%; */
    background-color: #dbe0e6;
   

`

export const Wrapper = styled.div`
    
`

export const Top = styled.div`
    min-height: 50vh;
    background-color: #FF8200;
    display: flex;
    justify-content: center;
    align-items: center;
    
    

`
export const TopWrapper = styled.div`
    
    margin: 0 15%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    "content image";

    @media screen and (max-width: 960px){
        margin: 0 2.5%;
        grid-template-columns: 1fr;
        grid-template-areas: 
         "image"
         "content";
    }
`

export const ImgSide = styled.div`
    grid-area: image;
    padding: 2.5%;
`

export const Image = styled.img`
    /* height: 10%; */
    width: 100%;
    border-radius: 20px;
    

`

export const ContentSide = styled.div`

    height: 100%;

    grid-area: content;
    padding: 2.5%;
    color: #000;
    /* align-items: center; */
    margin: auto 0;

    display: flex;
    justify-content: center;

    flex-direction: column;

    padding-bottom: 5%;


`

export const Title = styled.h3`
    font-size: 30px;
    font-family: "Kaushan Script", cursive;
    text-align: left;
    color: #006050;
    font-weight: normal;

    @media screen and (max-width: 960px){
        font-size: 26px;
    }
`

export const Paragraph = styled.p`
    padding-top: 20px;
    font-size: 21px;

    @media screen and (max-width: 960px){
        font-size: 16px;
        text-align: center;
    }
`

export const CarouselSection = styled.div`
    padding: 2.5% 15%;
    background-color: #fff;
    font-size: 36px;
    font-weight: normal;
    /* height: calc(100vh - 80px); */
    /* min-height: calc(80vh - 80px); */

    @media screen and (max-width: 960px){
        padding: 2.5% 2.5%;
        min-height: auto;
    }
   

`

export const CarouselTitle = styled.h3`
    font-family: "Kaushan Script", cursive;
    text-align: center;
    padding-bottom: 20px;
    color: #006050;

    
`

export const CarouselDiv = styled.div`
    /* min-height: calc(80vh - 80px); */
    margin: 0 2.5%;

    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;

   


    img{
        width: 100%;
        /* width: auto; */
        transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;

        cursor: pointer;
    }

    img:hover{
        

        transform: scale(1.05);
    }

    @media screen and (max-width: 960px){
        height: auto;

        img:hover{
        transition: .4s ease-out;
        transform: scale(1);
    }
    }
 
`

export const ButtonSection = styled.div`
    
    /* border: 2px dotted red; */
   
    display: flex;
    justify-self: center;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;

   
`

export const GalleryButton = styled.button`
   
    padding: 5px 30px;
    background-color: #FF8200;
    color: #fff;
    border: none;
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;

   
    
    

    @media screen and (max-width: 960px){
        font-size: 1rem;
        padding: 5px 30px;
        
        
    }
`

export const FeatureSection = styled.div`
   


    padding: 2.5% 15%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    "content image";

    @media screen and (max-width: 960px){
        

        padding: 2.5% 2.5%;
        grid-template-columns: 1fr;
        grid-template-areas: 
        "image"
        "content";
    }
 `

export const ListSection = styled.div`
    /* margin: 0 5%; */
    padding-top: 20px;

    ul{
        /* display: flex; */
        flex-wrap: wrap;
        list-style: none;
        
    }

    li{
        /* border: 3px dotted red; */
        
        /* display: inline; */
        font-size: 20px;
        /* width: 50%; */
        padding-right: 15px;
        margin-left: auto;
        
    }

    @media screen and (max-width: 960px){
        ul{
            display: flex;
            flex-direction: column;
            
        }

        li{
            width: 100%; 
            margin-left: 0;
            text-align: center;
        }
    }
 `
