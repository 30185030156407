import React from 'react'
import { ContentDesc } from '../../globalStyles';
import { Container, ContentSide, ContentTitle, ContentWrapper, ImageSide, ImageWrapper, Stats, StatsDetails, StatsLabel, StatsValue } from './AtAglanceElements'


const AtAglance = () => {
    return (
        <Container>
            <ImageSide>
                <ImageWrapper src={`${process.env.PUBLIC_URL}/mintPics/format10.jpg`} alt="The Mint Residence" />
            </ImageSide>

            <ContentSide>
                <ContentWrapper>
                    <ContentTitle>
                        At a glance
                    </ContentTitle>
                    <ContentDesc>
                        The Mint Residence is a home to over 150 young achievers who study, learn and connect within a vibrant community.
                    </ContentDesc>
                    <ContentDesc>
                        A unique  hostel equipped with modern amenities and sociable spaces designed to help you learn, grow and relax among like-minded friends.
                    </ContentDesc>

                </ContentWrapper>

            </ContentSide>
            <Stats>

                <StatsDetails>

                    <StatsValue color="#dbe0e6" >
                        80+
                    </StatsValue>
                    <StatsLabel>
                        Rooms
                    </StatsLabel>

                </StatsDetails>

                <StatsDetails>
                    <StatsValue color="#ff7518">
                        4+
                    </StatsValue>
                    <StatsLabel>
                        Universities
                    </StatsLabel>
                </StatsDetails>

                <StatsDetails>
                    <StatsValue color="#dbe0e6">
                        100+
                    </StatsValue>
                    <StatsLabel>
                        Residents
                    </StatsLabel>
                </StatsDetails>

                <StatsDetails>
                    <StatsValue color="#ff7518">
                        3+
                    </StatsValue>
                    <StatsLabel>
                        Nationalities
                    </StatsLabel>
                </StatsDetails>

                {/* </StatsWrapper> */}
            </Stats>
        </Container>
    )
}

export default AtAglance
