import React from 'react'
import { InnerTitle, Service, ServiceContainer, ServiceDesc, ServiceIcon, ServicesSection, ServiceSubTitle, ServiceTitle } from './ServicesElements'
import { services } from './data';


const Services = () => {



    return (
        <ServiceContainer>
            <ServiceTitle>Your rent includes </ServiceTitle>
            <ServiceSubTitle>Make a one off payment that includes</ServiceSubTitle>

            <ServicesSection>

                {services.map(srvc => {

                    return (
                        <Service>
                            <ServiceIcon>{srvc.icon}</ServiceIcon>

                            <InnerTitle>{srvc.title}</InnerTitle>
                            <ServiceDesc>{srvc.desc}</ServiceDesc>
                        </Service>
                    )

                })}

            </ServicesSection>

        </ServiceContainer>

    )
}

export default Services
