
export const productData = [
  {
    img: `${process.env.PUBLIC_URL}/mintPics/format17.jpg`,
    alt: 'Single Bed',
    name: 'Single Bed',
    desc:
      'This is a perfect space for single occupancy with a double wardrobe, study desk, shared bathroom and plenty of storage. ',
    pricing: 'Ksh 10,000 pp/pm',
    button: 'Make reservation',
    isReverse: true
  },
  {
    img: `${process.env.PUBLIC_URL}/mintPics/format18.jpg`,
    alt: 'Twin Room',
    name: 'Twin Room',
    desc:
      'This is perfect for sharing with a friend or relative and includes a fitted wardrobe, study desks and lots of storage.',
    pricing: 'Ksh 7,500 pp/pm',
    button: 'Make reservation',
    isReverse: true
  },
  {
    img: `${process.env.PUBLIC_URL}/mintPics/format23.jpg`,
    alt: 'short-stay',
    name: 'short-stay',
    desc:
      ' We offer a variety of comfortable and affordable options for travelers looking for a place to stay for a night or a few days',
    pricing: 'Ksh 4900 pp/wk',
    button: 'Make reservation',
    isReverse: true
  },

];
