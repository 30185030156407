import LandingPage from "./Pages/LandingPage";
import { BrowserRouter as Router, Route } from 'react-router-dom'
import GlobalStyle from "./globalStyles";
import Login from "./Components/Form/Login";
import NewNav from "./Components/NewNav";
import Profile from "./Components/Form/Profile";
import Booking from "./Components/Form/Booking";
import PublicRoute from "./Utils/PublicRoute";
import PrivateRoute from "./Utils/PrivateRoute";
import MyBookings from "./Components/MyBookings";
import ChangePassword from "./Components/Form/ChangePassword";
import MyProfile from "./Components/MyProfile";
import ForgotPassword from "./Components/Form/ForgotPassword";
import { checkExpired } from "./Utils/Common";
import ScrollToTop from "./Utils/ScrollToTop";
import Gallery from "./Components/Gallery";

const schedule = require('node-schedule');


function App() {




  //check every 1min if token has expired
  schedule.scheduleJob('* * * * *', function () {
    checkExpired()
  });


  return (
    <>
      <Router>
        <GlobalStyle />
        <ScrollToTop />
        <NewNav />

        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route exact path="/explore" component={Gallery} />

        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/reset" component={ForgotPassword} />
        <PrivateRoute exact path="/updateprofile" component={Profile} />
        <PrivateRoute exact path="/booking" component={Booking} />
        <PrivateRoute exact path="/mybookings" component={MyBookings} />
        <PrivateRoute exact path="/changePassword" component={ChangePassword} />
        <PrivateRoute exact path="/profile" component={MyProfile} />


      </Router>

    </>
  );
}

export default App;
