import styled from "styled-components";



export const Container = styled.div`

   
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
        "stats stats"
        "content image"
        
    ;

    margin: 2.5% 15%;

    @media screen and (max-width: 960px){
        grid-template-columns: 1fr;
        grid-template-areas: 
        "content"
        "image"
        "stats";
        
       
        margin: 2.5% 2.5%;
    }

`

export const Stats = styled.div`

    grid-area: stats;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: space-between;
   padding: 40px 0;

   
   

   @media screen and (max-width: 960px){

       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: 1fr 1fr;
       width: 100%;
    

   }
    
    
`

export const StatsWrapper = styled.div`
    


`

export const StatsDetails = styled.div`
    display: flex;
    /* justify-content: space-between; */
    align-items: center;


    @media screen and (max-width: 960px){
        margin-top: 5%;
        margin-left: 5%;
        
        
    }
`

export const StatsValue = styled.div`
    font-size: 18px;
    background-color: ${props => props.color ? props.color : "red"};;
    /* padding: 20px; */
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    /* margin-right: 15%; */

    @media screen and (max-width: 960px){
        margin-right: 5%;

    }
`
export const StatsLabel = styled.p`
    font-size: 18px;
    margin-left: 10px;
`

export const ImageSide = styled.div`
    grid-area: image;

    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 15%; */
    



`

export const ImageWrapper = styled.img`
    width:420px;
    border-radius: 20px;
    transition: .4s ease-out;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

  &:hover{
    transform: scale(1.05);
    transform: scale(1.02) ;
    transition: .4s ease-out;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
  
  


  @media screen and (max-width: 960px) {
        width: 100%;
        height: 100%;

        
  };



`

export const ContentSide = styled.div`
    grid-area: content;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 960px){
        padding-top: 30px;
    }

`

export const ContentWrapper = styled.div`
    display: inline-block;
    text-align: center;

`

export const ContentTitle = styled.h2`
    font-family: 'Kaushan Script';
    color: #006050;
    font-size: 36px;
    font-weight: normal;

    @media screen and (max-width: 960px){
        font-size: 26px;
    }
    


`

