import styled from 'styled-components';
import FeaturePic from '../../images/pattern.png';

export const FeatureContainer = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${FeaturePic});
  height: 100vh;
  max-height: 500px;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 0 1rem;

  

  p {
    margin-bottom: 1rem;
    font-size: 30px;

    @media screen and (max-width: 960px){
        font-size: 20px;
  }
  }
`;
export const FeatureButton = styled.button`
  font-size: 20px;
  padding: 0.6rem 3rem;
  border: none;
  background: #ff7518;
  color: #fff;
  transition: 0.2s ease-out;
  border-radius: 20px;

  &:hover {
    color: #fff;
    background: #fa9a5a;
    transition: 0.2s ease-out;
    cursor: pointer;
  }

  @media screen and (max-width: 960px){
    font-size: 16px;
  }

  a{
    color: #fff;
    text-decoration: none;
  }

  a:hover{
    color: #fff;
  }
`;

export const FeatureHeading = styled.h1`
  font-family: "Kaushan Script", sans-serif;
  font-size: 60px;

  @media screen and (max-width: 960px){
    font-size: 40px;
  }
`

