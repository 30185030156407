import { useState } from 'react'
import { FaTimes, FaBars } from 'react-icons/fa';

import { IconContext } from 'react-icons/lib';
import { token, logout, nameLocal } from '../../Utils/Common';
import { UserTitle } from '../Form/FormElements';
import './nav.css'
import navLogo from '../../images/newAsset 7.png';

import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu, NavItem, NavLinks, NavImg
} from './NavElements'


const NewNav = () => {

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);


    const isActive = {
        color: "#FF8200"
    };

    return (
        <>
            <IconContext.Provider value={{ color: '#000' }}>
                <Nav>
                    <NavbarContainer>
                        <NavLogo to="/">
                            <NavImg src={navLogo} alt="" />


                        </NavLogo>

                        <MobileIcon onClick={handleClick} >
                            {click ? <FaTimes /> : <FaBars />}
                        </MobileIcon>

                        <NavMenu onClick={handleClick} click={click}>
                            <NavItem>
                                <NavLinks activeStyle={isActive} exact to="/">
                                    Home
                                </NavLinks>
                            </NavItem>

                            <NavItem>
                                <NavLinks activeStyle={isActive} to="/explore">
                                    Explore
                                </NavLinks>
                            </NavItem>

                            <NavItem>
                                <a href="/#contact">
                                    {/* <NavLinks activeStyle={isActive} to="#"> */}
                                    ContactUs
                                    {/* </NavLinks> */}
                                </a>
                            </NavItem>

                            <NavItem>
                                <NavLinks activeStyle={isActive} to={{ pathname: `https://africomintranet.com/jobs` }} target="_blank">
                                   Jobs
                                </NavLinks>
                            </NavItem>



                            {/* <NavItem>
                                <NavLinks activeStyle={isActive} to="/booking">
                                    Book Hostel
                                </NavLinks>
                            </NavItem>

                            <NavItem>
                                <NavLinks activeStyle={isActive} to="/mybookings">
                                    My Booking
                                </NavLinks>
                            </NavItem> */}
                            {/* 
                            {token &&
                                <div class="dropdown">
                                    <button class="dropbtn">Welcome, {nameLocal}</button>
                                    <div class="dropdown-content">
                                        <NavLinks activeStyle={isActive} to="/profile">
                                            My Profile
                                        </NavLinks>

                                        <NavLinks activeStyle={isActive} to="/mybookings">
                                            My Bookings
                                        </NavLinks>

                                        <NavLinks activeStyle={isActive} to="/booking">
                                            Book Hostel
                                        </NavLinks>

                                        <NavLinks activeStyle={isActive} to="/changePassword">
                                            Change Password
                                        </NavLinks>


                                        <NavLinks activeStyle={isActive} to="/login" onClick={(e) => logout()}>
                                            <UserTitle title="Click to Logout">
                                                Logout
                                            </UserTitle>
                                        </NavLinks>
                                    </div>
                                </div>
                            } */}

                            {/* <NavItem>
                                {!token &&
                                    <NavLinks activeStyle={isActive} to="/login" >
                                        Resident login
                                    </NavLinks>}
                            </NavItem> */}

                        </NavMenu>

                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    )
}

export default NewNav

