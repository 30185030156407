import styled from "styled-components";
// import contactbg from './../../images/sweet-1.jpg'


export const ContactContainer = styled.div`
    background-color: #dbe0e6;
    /* background: #000; */
    padding: 5% 0%;

`

export const ContactWrapper = styled.div`
    margin: 0% 15%;
    display: grid;
   
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    "map form";

    @media screen and (max-width: 960px){
        margin: 0% 2.5%;

        display: grid;
   
        grid-template-columns: 1fr;
        grid-template-areas: 
        "form"
        "map";


    }

`

export const ContactLeft = styled.div`
    grid-area: map;

    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px 0px 0px 20px;



`

export const ContactRight = styled.div`
    grid-area: form;
    padding: 2.5% 5%;
    background: #fff;
    border-radius: 0px 20px 20px 0px;

    @media screen and (max-width: 960px){

        border-radius: 10px 10px 0px 0px;

    }
    
`

export const ContactTitle = styled.h2`
    font-family: 'Kaushan Script', sans-serif;
    font-weight: normal;
    color: #006050;
    text-align: center;
    font-size: 36px;

    @media screen and (max-width: 960px){
        font-size: 24px;
    }
`

export const ContactSubTitle = styled.h3`
    /* font-family: 'Kaushan Script', sans-serif; */
    color: #006050;
    font-weight: normal;
    font-size: 24px;
    background: #dbe0e6;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    text-align: center;
    border-radius: 20px;

    @media screen and (max-width: 960px){
        font-size: 18px;
        width: 80%;
        margin-bottom: 15px;
    }
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;

`
export const FormInput = styled.input`
    margin: 0.5% 0;
    border: 2px solid #dbe0e6;
    border-radius: 15px;
    padding: 15px;
    font-size: 16px;

    &:focus{
        border-radius: 15px; 
        
    }

    @media screen and (max-width: 960px){
        padding: 10px;
        border-radius: 10px;
        font-size: 12px;
    }
`

export const FormLabel = styled.label`

`

export const FormTextArea = styled.textarea`
    margin: 2.5% 0;
    border-radius: 15px;
    padding: 10px;
    height: 150px; 
    border: 2px solid #dbe0e6;
    font-size: 16px; 

    &:focus{
        border-radius: 15px; 
        
    }

    @media screen and (max-width: 960px){
        height: 80px;
        border-radius: 10px;
        font-size: 12px;
    }
`

export const FormButton = styled.button`
    margin-top: 2.5%;
    background: #ff7518;
    padding: 10px 40px;
    width: 30%;
    border: none;
    border-radius: 20px;
    font-size: 20px;
    color: #fff;
    margin-left: auto;
    cursor: pointer;

    &:hover{
        /* background: #FF8FA3; */
        color: #fff;
        background: #fa9a5a;
        transition: 0.2s ease-out;
        cursor: pointer;
        
    }
    
    @media screen and (max-width: 960px){
        font-size: 16px;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }  
    
`

