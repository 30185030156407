
import { FiWifi, FiLock } from 'react-icons/fi';
import { MdLibraryBooks, MdLocalLaundryService, MdScreenShare } from 'react-icons/md'
import { GiMeal, GiRockingChair, GiDesk } from "react-icons/gi";
import{GiKitchenKnives} from "react-icons/gi";

export const services = [
    {
        icon: <FiWifi />,
        title: "Ultra-fast WIFI",
        desc: "100mbs internet and wifi throught"
    },
    {
        icon: <FiLock />,
        title: "24/7 security",
        desc: "Security round the clock in every property"
    },
    {
        icon: <MdLibraryBooks />,
        title: "Study area",
        desc: "Private study space, featuring study desk/chair"
    },
    {
        icon: <MdScreenShare />,
        title: "Common area",
        desc: "Tv areas with comfortable seating"
    },
    {
        icon: <MdLocalLaundryService />,
        title: "On site laundry",
        desc: "Clean laundry areas with clean, soft water."
    },
    // {
    //     icon: <GiRockingChair />,
    //     title: "Connect Group",
    //     desc: "We offer connect group with a professional every Friday for our resident girls at no extra cost."
    // },
    {
        icon: <GiKitchenKnives />,
        title: "Culinary Haven: Student Kitchen",
        desc: "Access our fully equipped kitchen to cook your favorite meals and enjoy a home-away-from-home experience."
    },
    {
        icon: <GiDesk />,
        title: "Modern space",
        desc: "Rooms are installed with state of the art furniture and fittings to offer comfort. "
    }
]