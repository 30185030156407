import { useState, useEffect } from 'react'
import axios from 'axios';
import { Container, FormWrapper, Form, FormTitle, FormLabel, FormInput, FormButton, FormDetails, FormSelect, FormOption, Top, Button } from './FormElements'
import { stringToArray, totalCostforRoom } from './../../Utils/Bookings/BookingUtils'
import { today, userId, token, url } from '../../Utils/Common'
import { useHistory } from 'react-router';


const Booking = () => {

    let history = useHistory();

    const [period, setPeriod] = useState([]);
    const [dataBranch, setDataBranch] = useState([]);
    const [dataRoom, setDataRoom] = useState([]);
    const [dataRoomType, setDataRoomType] = useState([]);
    const [branchId, setBranchId] = useState('');
    const [roomTypes, setRoomTypes] = useState('');
    const [roomTypeName, setRoomTypeName] = useState('');
    const [roomId, setRoomId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('')
    const [roomCost, setRoomCost] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [beds, setBeds] = useState('');
    const [roomName, setRoomName] = useState('');
    const [branchName, setBranchName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fillInputs, setFillInputs] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState('');

    // console.log(period[0].period);
    //calculate cost
    let totalCost = totalCostforRoom(startDate, endDate, roomCost);
    if (startDate === '' || endDate === '') {
        totalCost = 0;
    }


    //converting roomTypes string (originated from db) to array so that we can use map 
    const roomTypeArray = stringToArray(roomTypes);


    //handle checkout
    const handleCheckOut = (e) => {
        setSelectedPeriod(parseInt(e));
        console.log(e)
        let checkOutDate = new Date(startDate);
        checkOutDate.setDate(checkOutDate.getDate() + parseInt(e));

        setEndDate(checkOutDate);

    }

    const handleRoomType = (e) => {
        setRoomTypeName(e);
    }

    const handleRoom = (e) => {
        setRoomId(e);
        dataRoom.filter(room => room.id === e).map(filtered => {
            return (setRoomCost(filtered.price),
                setBeds(filtered.beds_remaining), setRoomName(filtered.name));
        });
    }

    const handleBranch = (e) => {
        dataBranch.filter(branch => branch.id === e).map(filtered => {
            return (setBranchId(filtered.id),
                setRoomTypes(filtered.room_type),
                setBranchName(filtered.name));
        });
    }

    //handle booking ...counter to use for prompting user to review her application
    let firstSubmit = 0;



    const handleBooking = (e) => {
        e.preventDefault();

        if (branchName === '' || roomTypeName === '' || roomName === '' || totalCost === 0) {
            setFillInputs(true);
        }

        else if (firstSubmit === 0) {
            alert('Please Review Your Booking once more so as to avoid any inconvenience or mistakes that  might arise!.')
            firstSubmit += 1;
            setIsLoading(false);
            setFillInputs(false)
        }
        else {
            setIsLoading(true);



            const body =
            {
                tenant_id: userId,
                room_id: roomId,
                branch_name: branchName,
                room_name: roomName,
                branch_id: branchId,
                start_date: startDate,
                end_date: endDate,
                amount: totalCost,
                phone_number: phoneNumber,
                period: selectedPeriod
            };



            const headers = {
                'Authorization': `Basic ${token}`,
                "Content-Type": "application/json"

            };

            axios.post(url + '/booking/checkout', body, { headers })
                .then(response => {
                    // setIsLoading(false)
                    console.log('Request was sent successfull...')
                    console.log('please check your phone ..... and enter your pin.')
                    alert('Please check your phone and enter your pin... ')
                    alert('Press okay when you have completed the payment process')
                    history.push('/mybookings')
                    setIsLoading(false);
                    console.log(response)

                    //add logic ya kuadd kwa transaction table 

                })
                .catch(error => {
                    console.log("Something went wrong " + error)
                    setIsLoading(false);
                    alert('Something went wrong. Please contact support.');

                })
        }



    }


    useEffect(() => {
        //fetch all branches
        axios.get(url + '/branch/')
            .then(response => {

                setDataBranch(response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });

        const headers = {
            'Authorization': `Bearer ${token}`,

        };

        //fetch all rooms
        axios.get(url + '/room/', { headers })
            .then(response => {

                setDataRoom(response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });

        //fetch all room types
        axios.get(url + '/room-type/')
            .then(response => {

                setDataRoomType(response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });

        //fetch duration/period/stay length
        axios.get(url + '/period/', { headers })
            .then(response => {

                setPeriod(response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });

    }, []);



    return (
        <Container>




            <FormWrapper>
                <Form onSubmit={handleBooking}>
                    <Top>
                        <FormTitle> Hostel Booking </FormTitle>
                        <Button to='/mybookings'>My Bookings</Button>
                    </Top>


                    <FormDetails>
                        {fillInputs && <p style={{ color: "red" }}>Please fill all the inputs | Review the form</p>}

                        <FormLabel>Branch Name</FormLabel>
                        <FormSelect id="branch" name="branchName" onChange={(e) => handleBranch(e.target.value)} required >
                            <FormOption>Select Branch</FormOption>

                            {dataBranch.map((branch, key) => {
                                return (
                                    <>
                                        <FormOption value={branch.id} key={branch.id} name="branchName">{branch.name}</FormOption>
                                    </>
                                )
                            })}

                        </FormSelect>
                    </FormDetails>

                    <FormDetails>
                        <FormLabel>Room Type</FormLabel>
                        <FormSelect id="room" name="roomType" onChange={(e) => handleRoomType(e.target.value)} disabled={!branchId} required>
                            <FormOption>Select Room Type</FormOption>
                            {
                                roomTypeArray.map((room_types, key) => {

                                    return (
                                        <>
                                            <FormOption value={room_types} name="roomTypes">{room_types}</FormOption>

                                        </>
                                    )
                                })
                            }
                        </FormSelect>
                    </FormDetails>

                    <FormDetails>
                        <FormLabel>Room Name</FormLabel>
                        <FormSelect id="room" name="roomType" onChange={(e) => handleRoom(e.target.value)} disabled={!branchId} required>
                            <FormOption>Select Room Name/Number</FormOption>

                            {dataRoom.filter(room => room.room_type === roomTypeName && room.branch_id === branchId && room.beds_remaining !== 0).map(filteredroom => {
                                return (
                                    <>
                                        <FormOption value={filteredroom.id} key={filteredroom.id} name="branchName">{filteredroom.name}</FormOption>
                                    </>
                                )
                            })}

                        </FormSelect>
                    </FormDetails>


                    <FormDetails>
                        <FormLabel>Start Date</FormLabel>
                        <FormInput type="date" min={today()} onKeyDown={(e) => e.preventDefault()} onChange={(e) => setStartDate(e.target.value)} required />
                    </FormDetails>



                    <FormLabel>Duration</FormLabel>
                    <FormSelect id="room" name="roomType"
                        onChange={(e) => handleCheckOut(e.target.value)}
                        disabled={!startDate} required>
                        <FormOption value="0">Select Period/Duration</FormOption>
                        {period.map(prd => {
                            return (
                                <FormOption value={prd.period}>{prd.name}</FormOption>
                            )
                        })}

                    </FormSelect>



                    <FormDetails>
                        <FormLabel>Phone Number (MPESA Number)</FormLabel>
                        <FormInput type="tel" placeholder="254700000000" onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="254701234567" pattern="254[0-9]{9}"
                            required />
                        <small> Format: 254701234567</small>
                        {/* <small>Format: 123-45-678</small><br><br></br> */}
                    </FormDetails>
                    <FormDetails>
                        <FormLabel>Total Amount</FormLabel>
                        <FormInput type="text" value={totalCost} placeholder="Total Amount" required disabled />
                    </FormDetails>
                    <FormDetails>
                        {!isLoading && <FormButton>Book</FormButton>}
                        {isLoading && <FormButton>Booking...</FormButton>}
                    </FormDetails>

                </Form>
            </FormWrapper>
        </Container>
    )
}

export default Booking
