import styled from "styled-components";


export const ServiceContainer = styled.div`
    padding: 2.5% 15%;

`

export const ServiceTitle = styled.h2`
    text-align: center;
    font-family: 'Kaushan Script';
    color: #006050;
    font-size: 36px;
    font-weight: normal;

    @media screen and (max-width: 960px){
        font-size: 24px;
    }
`

export const ServiceSubTitle = styled.h4`
    text-align: center;
    color: #006050;
    font-weight: normal;
    font-size: 24px;

    @media screen and (max-width: 960px){
        font-size: 18px;
    }
`

export const ServicesSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;

`

export const Service = styled.div`
    /* border: 2px red dotted; */
    text-align: center;
    width: 20%;
    padding: 0 0.5%;
    margin-top: 10px;

    @media screen and (max-width: 960px){
        width: 100%

    }

   
`

export const ServiceIcon = styled.div`
    font-size: 35px;
    color: #ff7518;

    img{
        height: 50px;
        width: 50px;
        
    }
`
export const InnerTitle = styled.h3`


`

export const ServiceDesc = styled.p`
    opacity: 80%;
`