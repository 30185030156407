import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* justify-content: center; */
    align-items: center;
    height: calc(100vh - 80px);

`

export const Wrapper = styled.div`
    padding: 5% 15%;
    width: 100%;

    @media screen and (max-width: 960px){
        padding: 10% 2.5%;
        width: 100%;
        
    }


`

export const TableTitle = styled.h3`
    color: #006050;
    font-weight: normal;
    font-size: 24px;

    @media screen and (max-width: 960px){
        font-size: 20px;
    }
  
`

export const Top = styled.div`
display: flex;
justify-content: space-between;
align-items: center;

  margin-bottom: 2.5%;


`
export const Button = styled(Link)`
    padding: 10px 25px;
    border-radius: 10px;
    border: none;
    background-color: #ff7518;
    cursor: pointer;
    font-size: 18px;
    text-decoration: none;
    color: #fff;

    &:hover{
        transition: .3s ease-in-out;
        color: white;
        background-color: #fa9a5a;
    }

    @media screen and (max-width: 960px){
        font-size: 16px;
        padding: 5px 12.5px;
    }
`